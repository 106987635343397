// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();

// const url = require('./loaders/url')
// environment.loaders.prepend('url', url)
// environment.loaders.get('file').test = /\.(tiff|ico|svg|eot|otf|ttf|woff|woff2)$/i

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
// require.context('./images', true)

import "select2";

import "bootstrap";
// require('moment');
import moment from "moment";

window.moment = moment;

require("tempusdominus-bootstrap-4");
import "@fortawesome/fontawesome-free/js/all";

window.Dropzone = require("dropzone");

require("admin-lte");

// "myAwesomeDropzone" is the camelized version of the HTML element's ID
Dropzone.options.codesImportDropzone = {
  paramName: "codes_import[file]", // The name that will be used to transfer the file
  maxFilesize: 200, // MB
  acceptedFiles: ".txt,.csv,.xls",
  dictDefaultMessage: dropzoneIntl ? dropzoneIntl.defaultMessage : "Drop files here to upload",
  // uploadMultiple: true,
  accept: function (file, done) {
    if (file.name == "justinbieber.jpg") {
      done("Naha, you don't.");
    } else {
      done();
    }
  },
};

// "codesetsImportDropzone" is the camelized version of the HTML element's ID
Dropzone.options.codesetsImportDropzone = {
  paramName: "codesets_import[file]", // The name that will be used to transfer the file
  maxFilesize: 200, // MB
  acceptedFiles: ".txt,.csv,.xls",
  dictDefaultMessage: dropzoneIntl ? dropzoneIntl.defaultMessage : "Drop files here to upload",
  // uploadMultiple: true,
  accept: function (file, done) {
    if (file.name == "justinbieber.jpg") {
      done("Naha, you don't.");
    } else {
      done();
    }
  },
};
